import "./App.css";
import "react-bootstrap/dist/react-bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-scroll";
import About from "./pages/About";
import Project from "./pages/Project";
import Skills from "./pages/Skills";
import Contact from "./pages/Contact";
import { useState } from "react";

function App() {
  const [expanded, setExpanded] = useState(false);

  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  return (
    <div className="App background-container">
      <header className="App-header">
        <Navbar
          expand="md"
          bg="dark"
          variant="dark"
          fixed="top"
          className="navbar-light small"
          expanded={expanded}
        >
          <Container>
            <Navbar.Brand href="/" className="brand-logo">
              <img
                src="https://png.pngtree.com/png-vector/20230120/ourmid/pngtree-straw-hat-cartoon-illustration-png-image_6562738.png"
                alt="logo"
                width="40"
                height="40"
                className="d-inline-block align-top m-2"
              />
              <span>
                <span className="text-bg-danger brand-name fw-bold">A</span>
                Mainali
              </span>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto gap-5">
                <Link
                  className="nav-link"
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={handleNavLinkClick}
                >
                  About
                </Link>

                <Link
                  className="nav-link"
                  to="project"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={handleNavLinkClick}
                >
                  Project
                </Link>

                <Link
                  className="nav-link"
                  to="skill"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={handleNavLinkClick}
                >
                  Skill
                </Link>

                <Link
                  className="nav-link"
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={handleNavLinkClick}
                >
                  Contact
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <main className={"container-md pe-auto small"}>
        <section
          id="about"
          className="hero-box-area section-big container-fluid"
        >
          <About />
        </section>
        <section
          id="project"
          className="hero-box-area section-big container-fluid"
        >
          <Project />
        </section>
        <section
          id="skill"
          className="hero-box-area section-big container-fluid"
        >
          <Skills />
        </section>
        <section
          id="contact"
          className="hero-box-area section-big container-fluid"
        >
          <Contact />
        </section>
      </main>
    </div>
  );
}

export default App;
