function Skills() {
  return (
    <>
      <div className={"container about-container"}>
        <div className={"row"}>
          <div className={"col-lg-7 "}>
            <div className={"container-fluid card-style"}>
              <h3>
                {" "}
                <span className={"text-bg-danger brand-name fw-bold "}>P</span>
                roject
              </h3>
              <p className={"about-info"}>this is first project</p>
            </div>
          </div>
          <div className={"col-lg-5 "}>
            <div className={"container img-container"}>
              <img
                src={
                  "https://image.imgcreator.ai/ImgCreator/5177a7a187a64831a65f9d24b39ba3ec/original/1f7d55fe-2f7c-11ee-b6f9-0242ac110007_0.png"
                }
                alt={"logo"}
                className={"character-img"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Skills;
