import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Col, Container, Image, Row } from "react-bootstrap";
import character from "../images/character.png";

function About() {
  return (
    <>
      <Container fluid className={"m-2 "}>
        <Row>
          <Col className={"d-grid align-content-center"} sm={8}>
            <div
              className={
                "bg-transparent text-white text-start justify-content-center"
              }
            >
              <h2>
                <span className={"text-bg-danger brand-name fw-bold "}>A</span>
                bout
              </h2>
              <div>
                Hi, I am Abinash Mainali. I am a full stack developer. I have
                experience in developing web applications using React, Node,
                Express, and MongoDB. I have also worked on projects using C#,
                ASP.NET, and SQL Server.<br></br>
                <div className={"m-2 d-inline-block"}>
                  <a
                    href="https://www.linkedin.com/in/abinash-mainalli-2019291a4/"
                    className={"m-2"}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FaLinkedin className="icon" size={50} color={"white"} />
                  </a>
                  <a
                    href="https://github.com/AbinashMainali"
                    className={"m-2"}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FaGithub className="icon" size={50} color={"white"} />
                  </a>
                  <a
                    href="https://www.instagram.com/abinash_mainali/"
                    className={"m-2"}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <FaInstagram className="icon" size={50} color={"white"} />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col className={"d-grid align-items-center"} sm={4}>
            <Image
              srcSet={character}
              alt={"logo"}
              className={"rounded-circle img-fluid h-50 w-auto character-img"}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default About;
