import React, { useState } from "react";
import { BiLogoGmail } from "react-icons/bi";
import { FiGithub, FiInstagram, FiLinkedin, FiPhone } from "react-icons/fi";
import { IoShareSocial } from "react-icons/io5";
import { Container, Row, Col } from "react-bootstrap";

function Contact() {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
    console.log(formData);
    // Clear the form after submission
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <>
      <Container>
        <Row>
          <Col  >
            <div className={" p-3"}>
              <BiLogoGmail />
              <h4>Gmail</h4>
              <p className={" text-sm-center"}>
                <a
                  href={"mailto:mainali.blog@gmail.com"}
                  className={"text-decoration-none text-white small"}
                >
                  mainali.blog@gmail.com
                </a>
              </p>
            </div>
          </Col>
          <Col  >
            <div className={" p-3"}>
              <FiPhone />
              <h4>Phone</h4>
              <p className={"text-center"}>
                <a
                  href={"tel:+61-0472744209"}
                  className={"text-decoration-none text-white small"}
                >
                  +61-0472744209
                </a>
              </p>
            </div>
          </Col>
          <Col  >
            <div className={" p-3"}>
              <IoShareSocial />
              <h4>Contact Us</h4>
              <p className={"text-center"}>
                <a
                  href={
                    "https://www.linkedin.com/in/abinash-mainalli-2019291a4/"
                  }
                  target={"_blank"}
                  rel="noreferrer"
                  className={"text-decoration-none text-white m-2 "}
                >
                  <FiLinkedin />
                </a>
                <a
                  href={"https://www.instagram.com/abinash_mainali/"}
                  target={"_blank"}
                  rel="noreferrer"
                  className={"text-decoration-none text-white m-2 "}
                >
                  <FiInstagram />
                </a>
                <a
                  href={"https://github.com/AbinashMainali"}
                  target={"_blank"}
                  rel="noreferrer"
                  className={"text-decoration-none text-white m-2"}
                >
                  <FiGithub />
                </a>
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={"container-fluid w-auto"}>
              <h2 className={"m-2 "}>
                <span className={"text-bg-danger brand-name fw-bold "}>C</span>
                ontact Us
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3 text-start">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="John Doe"
                    required
                    aria-label="Your Name"
                  />
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control "
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="john@example.com"
                    required
                    aria-label="Email Address"
                  />
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name={"message"}
                    value={formData.message}
                    onChange={handleChange}
                    rows="3"
                    placeholder="Your message here..."
                    required
                    aria-label="Message"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-dark">
                  Submit
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
