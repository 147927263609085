import { FaInstagram, FaLaptop, FaMobile, FaUikit } from "react-icons/fa";

function Project() {
  return (
    <>
      <div className={"container"}>
        <div className="row">
          <div className="col-md-12 text-start m-3">
            <div className="section-title ">
              <h2>
                <span className={"text-bg-danger brand-name fw-bold "}>P</span>
                roject
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 g-4">
            <div className="col">
              <div className="hero-box box-one p-3">
                <center>
                  <FaLaptop />
                </center>
                <h3>Web Applications</h3>
                <p>
                  Empowering your business with web applications. Innovative and
                  user-friendly solutions to enhance productivity and customer
                  engagement.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="hero-box box-two p-3">
                <center>
                  <FaUikit />
                </center>
                <h3>Business Website</h3>
                <p>
                  Your digital storefront for success. Create a professional and
                  captivating business website that drives growth and leaves a
                  lasting impression.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="hero-box box-three p-3">
                <center>
                  <FaInstagram />
                </center>
                <h3>Custom Software</h3>
                <p>
                  Tailored solutions for your unique needs. Experience the
                  efficiency and innovation of custom software designed
                  specifically for your business.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="hero-box box-four p-3">
                <center>
                  <FaMobile />
                </center>
                <h3>Mobile App Development</h3>
                <p>
                  Bringing your app ideas to life. Expert mobile app development
                  for seamless user experiences and market-leading solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Project;
